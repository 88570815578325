 .grid_acordino{
    display: grid;
    grid-template-columns: 40% 40%;
    gap: 5%;
    background: var(--0-e-828-f, #d9eaec);
     margin-top: 5rem;  
     
}
.card-subtitle {
  margin-top: 2rem;
}
.accordino-image img{
  
  width: 30rem;
  height: 20rem;
  margin-top: 10rem;
}
@media screen and (max-width: 700px) {
    .grid_acordino{
      grid-template-columns: 1fr;
      grid-auto-rows: minmax(100px auto);
      margin-top: 24rem;
     
    }
    .accordino-image img{
      width: 30rem;
      height: 20rem;
      margin-top: 17rem;
    }
    }