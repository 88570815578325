.NavMenu__section {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  position: fixed;
  width: 101%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0px 105px;
  transition: 0.3s;
  margin-top: -1rem;
  z-index: 1;
}
.logo a {
  display: block;
}
.logo a img {
  width: 100%;
  display: block;
  margin-left: -2rem;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  transition: 0.5s;
}
.menu li {
  list-style-type: none;
}



.menu li a {
  text-decoration: none;
  display: block;
  padding: 40px 25px;

  transition: 0.3s;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: #030211;
}
.menu li a.active {
  font-weight: 800;
  
}
.menu li a:hover {
  box-shadow: 0 -1px 0px white inset, 500px 0 0 rgba(255, 255, 255, 0.04) inset;
  padding: 35px 25px 45px 25px;
  border-bottom: 1px solid rgb(206, 49, 49);
  font-weight: bold;
}
.hamburger {
  position: relative;
  width: 30px;
  height: 4px;
  background: black;
  border-radius: 10px;
  cursor: pointer;
  z-index: 200;
  transition: 0.3s;
}
.hamburger:before,
.hamburger:after {
  content: "";
  position: absolute;
  height: 4px;
  right: 0;
  background: black;
  border-radius: 10px;
  transition: 0.3s;
}
.hamburger:before {
  top: -10px;
  width: 20px;
}
.hamburger:after {
  top: 10px;
  width: 25px;
}
.toggle-menu {
  position: absolute;
  width: 30px;
  height: 100%;
  z-index: 300;
  cursor: pointer;
  opacity: 0;
}
.hamburger,
.toggle-menu {
  display: none;
}
.navigation input:checked ~ .hamburger {
  background: transparent;
}
.navigation input:checked ~ .hamburger:before {
  top: 0;
  transform: rotate(-45deg);
  width: 30px;
}
.navigation input:checked ~ .hamburger:after {
  top: 0;
  transform: rotate(45deg);
  width: 30px;
}
.navigation input:checked ~ .menu {
  right: 0;
}
.menu button {
  position: absolute;
  width: 95px;
  height: 44px;

  /* Linear (2 paints) */
  border: none;
  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  border-radius: 25px;
  margin-top: -1rem;
}
.logo {
  padding-left: 4rem;
}
.menu button p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 43px;
  /* identical to box height */

  text-align: center;

  /* Style */

  color: #ffffff;
}
/* Media Quary */
@media screen and (max-width: 992px) {
  .logo {
    padding-left: 1rem;
  }
  .hamburger,
  .toggle-menu {
    display: block;
  }
  .header {
    padding: 10px 40px;
  }
  .menu {
    justify-content: start;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: -21px;
    right: -346px;
    background: white;
    width: 300px;
    height: 100%;
    padding-top: 65px;
  }
  .menu li {
    width: 100%;
  }
  .menu li a {
    padding: 30px;
    font-size: 20px;
    box-shadow: 0.1px 0 rgba(255, 255, 255, 0.1) inset;
  }
  .logo a img {
    width: 86%;
    display: block;
    margin-left: -1rem;
    padding-top: 1rem;
  }
  .menu button {
    margin-left: -4rem;
  }
}
