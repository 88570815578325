.grid__contactpage {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: minmax(100px, auto);
  grid-column-gap: 10%;
  /* gap: 10%; */
  margin-left: 8%;
  padding: 8rem;
}
/* .grid__contact{
    display: grid;
    grid-template-rows: 40% 40%;
    gap: 10%;
    
} */
.subdiv11 {
  padding: 2rem;
  width: 50%;
  font-family: "Syne";
  font-style: normal;

  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;

  color: rgb(161, 53, 53);
}

.subdiv2 {
  padding: 2rem;
  margin-left: 21rem;
  width: 50%;
  font-family: "Syne";
  font-style: normal;
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* text-transform: capitalize; */
  color: rgb(161, 53, 53);
  margin-top: -14rem;
}
.form {
  display: grid;
  grid-template-rows: 20% 20%;
  font-family: "Syne";
  font-style: normal;

  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;

  /* text-transform: capitalize; */
  color: rgb(161, 53, 53);
}
.team1 {
  width: 996px;
  height: 68px;
  margin-left: 15%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  text-align: center;

  color: #7c7c7c;
}
.textarea {
  padding: 0 2rem;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  margin-left: 2rem;
  height: 200px;
  width: 190%;
  /* padding-left: 18rem; */
  /* margin-top: 3rem; */
}
.contact-socialmedia li {
  float: left;
  padding: 0.5rem;
}
.formbox1 {
  /* width: 50px; */
  height: 50px;
  padding: 0 1.5rem;
  box-sizing: border-box;
  background: #ffffff;
  opacity: 0.87;
  border: 1px solid #d3d3d3;
  border-radius: 100px;
}
.formbox1:hover {
  color: #0e828f;
  opacity: #0e828f;
}
.follow {
  /* margin-bottom: 4rem; */
}
.formcolor1 {
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;

  text-transform: capitalize;

  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.formcolortext {
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  margin-left: 2rem;
  text-transform: capitalize;

  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.contact_text {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: chocolate;
}
.contact_text2 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;

  color: #000000;
}
.submit1 {
  padding: 16px 33px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 1rem;
  color: white;
  padding: 1rem;
  margin-left: 2rem;
  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  border-radius: 40px;
  border: none;
}
.grid___contact2 {
  display: grid;
  grid-template-rows: 40% 40%;
  gap: 5%;
}
.Team p {
  font-family: "Syne";
  font-style: normal;
  font-weight: 700;
  font-size: 68px;
  line-height: 120px;
   /* margin-top: 4rem; */
  text-align: center;

  color: #0e828f;
}
.follow {
  margin-top: -10rem;
  /* text-align: center; */
  padding: 1rem;
  font-family: "Syne";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  color: rgb(210, 30, 30);
}
@media only screen and (max-width: 700px) {
  .grid__contactpage {
    grid-template-columns: 1fr;
    margin-left: -8;
    padding: 5rem;
  }
  .team1 {
    width: auto;
    height: auto;
    margin-left: 5%;
  }
  .Team p {
    font-weight: 500;
    font-size: 60px;
  }
  .formbox1 {
    padding: 1rem;
  }
  .textarea {
    height: 11rem;
    width: 17rem;
    padding: 1rem;
    margin-left: -3rem;
  }
  .follow {
    margin-left: -5rem;
    margin-top: 1rem;
  }
  .submit1 {
    margin-left: -1rem;
  }
  .formcolortext {
    margin-left: -3rem;
  }
  .lets {
    margin-left: -3rem;
  }
  .subdiv11 {
    padding: 0px;
    width: 100%;
    margin-left: -3rem;
  }
  .subdiv2 {
    padding: 0px;
    width: 100%;
    margin-left: -3rem;
    margin-top: 2rem;
  }
}
.contact-socialmedia li a {
  /* color: #e03030; */
  list-style: none;
}
.contact-socialmedia li {
  list-style: none;
}
@media (min-width: 1920px) and (max-width: 2560px) {
  .textarea {
    margin-left: 2rem;
    height: 100px;
    width: 141%;
  }
}
