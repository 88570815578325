.filter-buttons {
  margin-bottom: 20px;
  background: #fff;
  color: #0e0606;
  padding: 8px 46px;
  text-transform: uppercase;

  font-size: 17px;
  border-radius: 50px;
}
.plus img {
  margin-top: -20rem;
}
.filters {
  margin-inline: 1rem;
  background: #fff;
  color: #0e0606;
  padding: 8px 46px;
  border: 1px solid;
  text-transform: uppercase;
  font-size: 17px;
  border-radius: 50px;
}
.filters:hover {
  background: linear-gradient(98.88deg, #f05f2b 13.12%, #ec1771 119.96%);
  cursor: pointer;
  color: #fff;
}

.image-gallery {
  display: grid;
  grid-template-columns: 28% 28% 28%;
  gap: 20px;
  margin-left: 9rem;
}

.image-item {
  position: relative;
}

.image-item img {
  width: 100%;
}
.image-overlay a {
  color: #fff;
}
.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-overlay img {
  margin-top: -13rem;
  margin-left: 5rem;
  width: auto;
}
.image-overlay {
  background: rgba(29, 28, 28, 0.75);
  background-blend-mode: multiply;
  backdrop-filter: blur(4px);
}
.filters button.active {
  border-bottom-color: linear-gradient(
    98.88deg,
    #f05f2b 13.12%,
    #ec1771 119.96%
  );
}
.image-item:hover .image-overlay {
  opacity: 1;
}
@media only screen and (max-width: 700px) {
  .image-gallery {
    grid-template-columns: 1fr;
    margin-left: 3rem;
  }
  .filters-button {
    margin: 0 5px;
    font-size: 14px;
    background: #fff;
    color: #0e0606;
    padding: 2px 10px;
    text-transform: uppercase;
    font-size: 17px;
    border-radius: 30px;
    background-color: transparent;
    border-color: #0e0606;
    cursor: pointer;
    transition: border-bottom-color 0.3s;
  }
  .filter-buttons {
    margin-bottom: 20px;
    background: #fff;
    color: #0e0606;
    padding: 8px 6px;
    text-transform: uppercase;
    font-size: 11px;
    border-radius: 50px;
  }
}
