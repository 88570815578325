/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.galleryItem img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.galleryContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  row-gap: -5px;
  /* margin-top: -30rem; */
}

.galleryWrapper {
  padding: 5% 10%;
}

.filterItem ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 20px;
  margin-bottom: 30px;
}

.filterItem ul button {
  background: #fff;
  color: #0e0606;
  padding: 8px 46px;
  text-transform: uppercase;
  /* border: rgb(15, 15, 15); */
  font-size: 17px;
  border-radius: 50px;
}

.filterItem ul button:hover {
  background: #d82929;
  cursor: pointer;
  color: #fff;
}

.galleryItem img {
  border-radius: 15px;
}
.grid__item {
  padding: 1rem;
  margin-top: 3rem;
}
.grid__item p {
  font-family: "Syne";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 36px;
  /* margin-top: 10rem; */
  padding: 1rem;
  /* padding-left: 8rem; */
  text-align: center;
  color: #0f0b0b;
}
@media only screen and (max-width: 700px) {
  .galleryContainer {
    grid-template-columns: 1fr;
    padding: 0rem;
    padding-left: 1rem;
    margin-left: -3rem;
  }
  .galleryItem {
    padding: 1rem;
    margin-top: 0rem;
  }
  .grid__item {
    padding: 1rem;
    margin-top: 1rem;
  }
  .filterItem ul button {
    padding: 1rem;
    margin-left: -1rem;
  }
  .galleryItem img {
    border-radius: 30px;
    width: 22rem;
    /* padding-left: 3rem; */
    margin-left: 2rem;
  }
  .grid__item p {
    font-size: 39px;
    padding: 0rem;
  }
}
