.swiper-slide img {
  display: block;
  width: 38%;
  height: 100%;
  object-fit: cover;
}
.Silder__Text {
  margin-top: -3rem;
  margin-bottom: 8rem;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 133.2%;
  /* identical to box height, or 27px */

  text-transform: capitalize;

  /* Style */

  color: #25233a;
}
@media (max-width: 700px) {
  #Log__swiper {
    margin-left: 8rem;
  }
  #Log__swiper
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    margin-left: -6rem;
  }
}
.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
  display: none;
}
